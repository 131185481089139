import React from 'react';
import { BrowserRouter as Router, Link, Route, Routes } from "react-router-dom";
import Indexx500ProdBSC from './pages/Indexx500ProdBSC';
import Indexx500ProdETH from './pages/Indexx500ProdETH';
import IndexxCryptoProdBSC  from './pages/IndexxCryptoProdBSC';
import IndexxCryptoProdETH from './pages/IndexxCryptoProdETH';
import IndexxUSDPProdBSC  from './pages/IndexxUSDPProdBSC';
import IndexxUSDPProdETH from './pages/IndexxUSDPProdETH';
import IndexxExProdBSC  from './pages/IndexxExProdBSC';
import IndexxExProdETH from './pages/IndexxExProdETH';
import './App.css'; // Import your CSS file


function App() {
  return (
    <Router>
      <div className="App">
        <header className="container-fluid">
          <div className="row">
            <div className="nav-main col-12 col-md-6">
              <ul>
                {/* ... (repeat the structure below for each link) */}
                <li>
                  <p>BSC</p>
                  <Link to="/indexx500-prod-bsc">Indexx 500 Binance Production (IN500)</Link> <br />
                  <Link to="/indexxcrypto-prod-bsc">Indexx Crypto Binance Production (INXC)</Link> <br />
                  <Link to="/indexxusdp-prod-bsc">Indexx USD+ Binance Production (IUSD+)</Link> <br />
                  <Link to="/indexxex-prod-bsc">Indexx Exchange Binance Production (INEX)</Link> <br />
                  <hr />
                  <p>ETH</p>
                  <Link to="/indexx500-prod-eth">Indexx 500 Ethereum Production (IN500)</Link>  <br />
                  <Link to="/indexxcrypto-prod-eth">Indexx Crypto Ethereum Production (INXC)</Link> <br />
                  <Link to="/indexxusdp-prod-eth">Indexx USD+ Ethereum Production (IUSD+)</Link> <br />
                  <Link to="/indexxex-prod-eth">Indexx Exchange Ethereum Production (INEX)</Link> <br />
                </li>
              </ul>
            </div>
          </div>
        </header>

        <Routes>
          <Route path="/indexx500-prod-bsc" element={<Indexx500ProdBSC />} />
          <Route path="/indexx500-prod-eth" element={<Indexx500ProdETH />} />
          <Route path="/indexxcrypto-prod-bsc" element={<IndexxCryptoProdBSC />} />
          <Route path="/indexxcrypto-prod-eth" element={<IndexxCryptoProdETH />} />
          <Route path="/indexxusdp-prod-bsc" element={<IndexxUSDPProdBSC />} />
          <Route path="/indexxusdp-prod-eth" element={<IndexxUSDPProdETH />} />
          <Route path="/indexxex-prod-bsc" element={<IndexxExProdBSC />} />
          <Route path="/indexxex-prod-eth" element={<IndexxExProdETH />} />
          {/* ... (define routes for other pages) */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
