import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';

import { connectToMetamask, feeWallet, getBalance, getContract, getCurrentAccount, getTotalSupply, mintTokens, transferTokens } from '../ethereumService';

import { contractINXCABI } from "../helpers/IndexxCryptoBsc";

const contractAddress = "0x7325e062ea31e7b977fbebbcc45de30c3e894988";

const IndexxCryptoProd = () => {
    const [toAddress, setToAddress] = useState('');
    const [amount, setAmount] = useState('');
    const [toAddress1, setToAddress1] = useState('');
    const [amount1, setAmount1] = useState('');
    const [accountAddress, setAccountAddress] = useState('');
    const [, setSigner] = useState(null);
    const [contract, setContract] = useState(null);
    const [balance, setBalance] = useState();
    const [contractAddressInfo, setContractAddressInfo] = useState(contractAddress);
    const [transferFeeWallet, setTransferFeeWallet] = useState('');
    const [feeWalletBalance, setFeeWalletBalance] = useState('');
    const [tokenSupply, setTokenSupply] = useState('');
    const [currentWallet, setCurrentWallet] = useState('');
    const [currentWalletBalance, setCurrentWalletBalance] = useState('');

    const connectWallet = async () => {
        const { signer, provider } = await connectToMetamask("bsc");
        if (signer) {
            setSigner(signer);
            setContract(getContract(signer, contractAddress, contractINXCABI));
        }
    };

    useEffect(() => {
        connectWallet();
    }, []);

    useEffect(() => {
        if (window.ethereum) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            setSigner(signer);
            setContract(getContract(signer, contractAddress, contractINXCABI));
        }
    }, []);


    useEffect(() => {
        // create a function to fetch initial data here 
        // and set the respective state values
        const fetchData = async () => {
            if (contract) {
                try {
                    // You would replace 'METHOD_NAME' and 'ARGS' with actual method names and arguments from your smart contract
                    const feeWalletValue = await feeWallet(contract);
                    setTransferFeeWallet(feeWalletValue);

                    const balance = await getBalance(contract, feeWalletValue);
                    setFeeWalletBalance(balance);

                    const supply = await getTotalSupply(contract);
                    setTokenSupply(supply);

                    const provider = new ethers.providers.Web3Provider(window.ethereum);
                    const wallet = await getCurrentAccount(provider);
                    setCurrentWallet(wallet);

                    const walletBalance = await getBalance(contract, wallet);
                    setCurrentWalletBalance(walletBalance);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        };

        fetchData();
    }, [contract]);

    const mint = async () => {
        try {
            if (contract && toAddress && amount) {
                const tx = await mintTokens(contract, toAddress, ethers.utils.parseUnits(amount));
                console.log('Transaction:', tx);
            }
        } catch (error) {
            console.error('Error minting tokens:', error);
        }
    };

    const transfer = async () => {
        try {
            if (contract && toAddress && amount) {
                const tx = await transferTokens(contract, toAddress, ethers.utils.parseUnits(amount));
                console.log('Transaction:', tx);
            }
        } catch (error) {
            console.error('Error transferring tokens:', error);
        }
    };

    const checkBalance = async () => {
        try {
            if (contract && accountAddress) {
                const balance = await contract.balanceOf(accountAddress);
                console.log(`Balance: ${ethers.utils.formatUnits(balance.toString())}`);
                setBalance(ethers.utils.formatUnits(balance.toString()))
            }
        } catch (error) {
            console.error('Error checking balance:', error);
        }
    };

    return (
        <div>
            <h1>Indexx Crypto Production Binance Page</h1>
            <table>
                <tbody>
                    <tr>
                        <td>1. Contract Address:</td>
                        <td>{contractAddressInfo}</td>
                    </tr>
                    <tr>
                        <td>2. Transfer Fee Collection wallet / Admin Wallet :</td>
                        <td><span>{transferFeeWallet}</span></td>
                    </tr>
                    <tr>
                        <td>3. Transfer Fee/Admin Wallet balance :</td>
                        <td><span>{feeWalletBalance}</span></td>
                    </tr>
                    <tr>
                        <td>4. Token Supply:</td>
                        <td><span>{tokenSupply}</span></td>
                    </tr>
                    <tr>
                        <td>5. Current Wallet:</td>
                        <td><span>{currentWallet}</span></td>
                    </tr>
                    <tr>
                        <td>6. Current Wallet Balance:</td>
                        <td><span>{currentWalletBalance}</span></td>
                    </tr>
                    <tr>
                        <td>7. Mint Token:</td>
                        <td>
                            <input
                                style={{ width: "auto" }}
                                type="text"
                                value={toAddress}
                                onChange={(e) => setToAddress(e.target.value)}
                                placeholder=" To Wallet Address"
                            />
                            <input
                                style={{ width: "auto" }}
                                type="number"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                placeholder=" Amount to mint"
                            />
                            <button onClick={mint}>Mint</button>
                        </td>
                    </tr>
                    <tr>
                        <td>8. Transfer Token:</td>
                        <td>
                            <input
                                style={{ width: "auto" }}
                                type="text"
                                value={toAddress1}
                                onChange={(e) => setToAddress1(e.target.value)}
                                placeholder=" To Wallet Address"
                            />
                            <input
                                style={{ width: "auto" }}
                                type="number"
                                value={amount1}
                                onChange={(e) => setAmount1(e.target.value)}
                                placeholder=" Amount to transfer"
                            />
                            <button onClick={transfer}>Transfer</button>
                        </td>
                    </tr>
                    <tr>
                        <td>9. Show Any Wallet Balance:</td>
                        <td>
                            <input
                                style={{ width: "auto" }}
                                type="text"
                                value={accountAddress}
                                onChange={(e) => setAccountAddress(e.target.value)}
                                placeholder=" Wallet Address"
                            />
                            <button onClick={checkBalance}>Get</button>
                            <br></br>
                            {balance !== undefined && (
                                <h1> Balance of address {accountAddress} is {balance}</h1>
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default IndexxCryptoProd;
