import { ethers } from "ethers";

export const connectToMetamask1 = async () => {
    if (window.ethereum) {
        try {
            // Request account access
            await window.ethereum.request({ method: 'eth_requestAccounts' });

            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();

            // Get the current network chainId
            let n = window.ethereum.chainId;
            try {
                if (n !== "0x38") {
                    // If not on BSC Mainnet, try switching to it
                    await window.ethereum.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                chainId: "0x38",
                                chainName: "BSC Mainnet",
                                rpcUrls: ["https://bsc-dataseed1.binance.org/"],
                                blockExplorerUrls: ["https://bscscan.com"],
                                nativeCurrency: {
                                    symbol: "BNB",
                                    decimals: 18,
                                },
                            },
                        ],
                    });
                }
            } catch (addError) {
                console.error('Error adding BSC Mainnet:', addError);
            }

            return { signer, provider };
        } catch (accessError) {
            console.error(accessError);
        }
    } else {
        console.log('Please install MetaMask!');
        return null;
    }
};

export const connectToMetamask = async (network = 'eth') => {
    if (window.ethereum) {
        try {
            // Request account access
            await window.ethereum.request({ method: 'eth_requestAccounts' });

            // Get the current network chainId
            let n = window.ethereum.chainId;
            try {
                if ((network === "eth" && n !== "0x1") || (network === "bsc" && n !== "0x38")) {
                    await window.ethereum.request({
                        method: "wallet_switchEthereumChain",
                        params: [{ chainId: network === "eth" ? "0x1" : "0x38" }],
                    });
                }
            } catch (switchError) {
                console.error(`Error switching to ${network === "eth" ? "Ethereum" : "BSC"}:`, switchError);
                try {
                    // If switching failed, try adding the new network
                    await window.ethereum.request({
                        method: "wallet_addEthereumChain",
                        params: [network === 'eth' ? {
                            chainId: '0x1',
                            chainName: 'Ethereum Mainnet',
                            rpcUrls: ['https://mainnet.infura.io/v3/YOUR_INFURA_PROJECT_ID'],
                            blockExplorerUrls: ['https://etherscan.io/'],
                            nativeCurrency: {
                                symbol: 'ETH',
                                decimals: 18,
                            },
                        } : {
                            chainId: '0x38',
                            chainName: 'BSC Mainnet',
                            rpcUrls: ['https://bsc-dataseed1.binance.org/'],
                            blockExplorerUrls: ['https://bscscan.com'],
                            nativeCurrency: {
                                symbol: 'BNB',
                                decimals: 18,
                            },
                        }],
                    });
                } catch (addError) {
                    console.error(`Error adding ${network === "eth" ? "Ethereum" : "BSC"} network:`, addError);
                }
            }

            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();


            return { signer, provider };
        } catch (accessError) {
            console.error(accessError);
        }
    } else {
        console.log('Please install MetaMask!');
        return null;
    }
};


export const getContract = (signer, contractAddress, contractABI) => {
    return new ethers.Contract(contractAddress, contractABI, signer);
};


export const mintTokens = async (contract, to, amount) => {
    try {
        const tx = await contract.mint(to, amount);
        return tx;
    } catch (error) {
        console.error(error);
    }
};

export const transferTokens = async (contract, to, amount) => {
    try {
        const tx = await contract.transfer(to, amount);
        return tx;
    } catch (error) {
        console.error(error);
    }
};

export const getTotalSupply = async (contract) => {
    try {
        const totalSupply = await contract.totalSupply();
        return ethers.utils.formatEther(totalSupply);
    } catch (error) {
        console.error(error);
    }
};

export const approve = async (contract, spender, amount) => {
    try {
        const tx = await contract.approve(spender, amount);
        return tx;
    } catch (error) {
        console.error(error);
    }
};

export const transferFrom = async (contract, from, to, amount) => {
    try {
        const tx = await contract.transferFrom(from, to, amount);
        return tx;
    } catch (error) {
        console.error(error);
    }
};

export const getAllowance = async (contract, owner, spender) => {
    try {
        const allowance = await contract.allowance(owner, spender);
        return allowance.toString();
    } catch (error) {
        console.error(error);
    }
};

export const getTransaction = async (provider, txHash) => {
    try {
        const transaction = await provider.getTransaction(txHash);
        return transaction;
    } catch (error) {
        console.error(error);
    }
};


export const feeWallet = async (contract) => {
    try {
        const feeWalletValue = await contract.feeAdmin();
        return feeWalletValue.toString();
    } catch (error) {
        console.error(error);
    }
};

export const getBalance = async (contract, account) => {
    try {
        if(!ethers.utils.isAddress(account)){
            throw new Error("Invalid address");
        }
        const balance = await contract.balanceOf(account);
        return ethers.utils.formatEther(balance);
    } catch (error) {
        console.error(error);
    }
};

export async function getCurrentAccount(provider) {
    try {
      const signer = provider.getSigner();
      const account = await signer.getAddress();
      return account;
    } catch (err) {
      console.log(err);
    }
}

